import React, { useEffect, useState } from "react";
import Img from "gatsby-image";
import { StaticQuery, Link, graphql } from "gatsby";
import { Container, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import goArrow from "../assets/icon-arrow.svg";
import goArrowWhite from "../assets/icon-arrow-white.svg";
import Seo from "../components/SEO";
import Started from "../components/Layout/started";
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import * as pageStyles from "./page.module.less";
import * as styles from "./news.module.less";
import LocalPagination from "../components/pagination";
import { animateScroll as scroll } from "react-scroll";

const ARTICLE_POST_QUERY = graphql`
  query ArticlePostQuery {
    blogDefault: file(
      relativePath: { eq: "../assets/Blog/blog_default_fullhd.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark(
      filter: { fields: { sourceInstanceName: { eq: "articles" } } }
      sort: { order: DESC, fields: frontmatter___date }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            sample
            tags
            date(formatString: "MMMM DD, YYYY")
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 526) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
          excerpt(pruneLength: 220)
        }
      }
    }
  }
`;

const GoArrow = styled.div`
  width: 100%;
  text-align: left;
  margin-top: ${({ noMargin }) => (noMargin ? "0" : "20px")};
  img {
    width: ${({ noMargin }) => (noMargin ? "13px" : "20px")};
    transition: all 0.5s;
  }
`;

const GoText = styled.span`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #1e5bb5;
  margin-right: 8px;
`;

const NewsHeading = styled.div`
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #242d41;
`;

const LowerBlogText = styled.h4`
  color: #242d41;
  font-size: 28px;
  font-weight: 600;
  padding: 0 0 16px 0;
  line-height: 1.31;
  margin-bottom: 0;
`;

const BlogText = styled.div`
  font-size: 16px;
  color: rgba(36, 45, 65, 0.9);
  line-height: 1.56;
`;

const PostWrapper = styled.div`
  width: 100%;
`;

const Border = styled.hr`
  background-color: #eeeff3;
`;

const StandardBlogPostContent = styled.div`
  position: relative;
  background: #fff;
  transition: all 0.2s;
  margin-bottom: 60px;
`;

const GradientSection = styled.div`
  height: 45px;
  width: 100%;
  background-image: linear-gradient(to top, #f0f5f9, #ffffff);
  @media screen and (max-width: 768px) {
    margin-top: ${({ noMargin }) => (noMargin ? "0" : "-65px")};
  }
`;

const EventCard = styled.div`
  border-radius: 16px;
  box-shadow: 0 6px 20px 0 rgba(36, 45, 65, 0.1);
  background-color: #fff;
  padding: 16px;
  margin-bottom: 20px;
`;

const EventDate = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.29;
  color: rgba(36, 45, 65, 0.7);
  margin-bottom: 4px;
`;

const EventTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: #242d41;
  margin-bottom: 12px;
`;

const EventType = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: rgba(36, 45, 65, 0.9);
  margin-bottom: 12px;
`;

const EventButton = styled.button`
  height: 40px;
  padding: 0 24px;
  font-size: 13px;
  border-radius: 21px;
  border: solid 2px #1e5bb5;
  background-color: #1e5bb5;
  color: #fff;
  display: flex;
  align-items: center;
  span {
    flex-shrink: 0;
    margin-right: 8px;
  }
`;

const DateWrapper = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: rgb(36, 45, 65);
  @media (max-width: 821px) {
    margin-top: 0;
  }
`;

const StyledRow = styled(Row)`
  margin-top: 40px;
  padding-bottom: 28px;
`;

const Articles = (props) => {
  const { blogDefault } = props.data || {};
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  const events = [
    {
      link: "https://www.aircraftit.com/webinars/trustflight-electronic-technical-logbook-etl-elb-solution-demo-webinar/?area=mro",
      date: "On-demand webinar",
      title: "Electronic Tech Log webinar",
      type: "TrustFlight overview, product walkthrough, implementation and main takeaways",
    },
  ];

  return (
    <Layout>
      <Seo title="Articles" />
      <PageHeader mediumHeader header="Articles" />
      <StaticQuery
        query={ARTICLE_POST_QUERY}
        render={({ allMarkdownRemark }) => {
          let articles = allMarkdownRemark.edges.filter((item) =>
            item?.node?.frontmatter?.tags.includes("Article")
          );
          const paginatedArticles = articles.slice(
            (currentPage - 1) * pageSize,
            currentPage * pageSize
          );

          setTotal(articles.length);

          return (
            <Container key={allMarkdownRemark}>
              <StyledRow>
                <Col lg={9} md={12}>
                  {paginatedArticles.map((edge, index) => {
                    return (
                      <StandardBlogPostContent>
                        <Link
                          to={edge.node.frontmatter.slug}
                          className={pageStyles.fullLink}
                        />
                        <PostWrapper>
                          <Row>
                            <Col md={12} lg={12} xl={12}>
                              <DateWrapper>
                                {edge.node.frontmatter.date}
                              </DateWrapper>
                              <LowerBlogText>
                                {edge.node.frontmatter.title}
                              </LowerBlogText>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={5} sm={12} className={styles.showMobile}>
                              <Img
                                fluid={
                                  edge.node.frontmatter.featuredImage
                                    ? edge.node.frontmatter.featuredImage
                                        .childImageSharp.fluid
                                    : blogDefault?.childImageSharp?.fluid
                                }
                                className={styles.standardBlogImage}
                              />
                            </Col>
                            <Col md={7} sm={12}>
                              <BlogText>
                                {edge.node.frontmatter.sample}
                              </BlogText>
                              <GoArrow>
                                <GoText>Read more</GoText>
                                <img src={goArrow} alt="goArrow" />
                              </GoArrow>
                            </Col>
                            <Col md={5} sm={12} className={styles.noShowMobile}>
                              <Img
                                fluid={
                                  edge.node.frontmatter.featuredImage
                                    ? edge.node.frontmatter.featuredImage
                                        .childImageSharp.fluid
                                    : blogDefault?.childImageSharp?.fluid
                                }
                                className={styles.standardBlogImage}
                              />
                            </Col>
                          </Row>
                        </PostWrapper>
                      </StandardBlogPostContent>
                    );
                  })}
                </Col>
                <Col md={3}>
                  <NewsHeading>Events</NewsHeading>
                  <Border />
                  {events.map((event) => {
                    return (
                      <EventCard>
                        <a
                          href={event.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <EventDate>{event.date}</EventDate>
                          <EventTitle>{event.title}</EventTitle>
                          <EventType>{event.type}</EventType>
                          <EventButton>
                            <span>Find out more</span>
                            <GoArrow noMargin>
                              <img src={goArrowWhite} alt="goArrow" />
                            </GoArrow>
                          </EventButton>
                        </a>
                      </EventCard>
                    );
                  })}
                </Col>
              </StyledRow>
              {total > 10 ? (
                <LocalPagination
                  total={total}
                  setPageSize={setPageSize}
                  setCurrentPage={setCurrentPage}
                  passedCurrentPage={currentPage}
                  width="75%"
                  onChange={() => {
                    scroll.scrollToTop({
                      duration: 500,
                      smooth: true,
                      offset: -100,
                    });
                  }}
                />
              ) : null}
            </Container>
          );
        }}
      />
      <GradientSection noMargin={total > 10} />
      <Container>
        <Started />
      </Container>
    </Layout>
  );
};
export default Articles;
